export const jsonSafeParse = <T>(
  text: unknown,
  reviver?: (key: any, value: any) => any
): T | any => {
  if (typeof text !== 'string') return text;
  const firstChar = text[0];
  if (firstChar !== '{' && firstChar !== '[' && firstChar !== '"') return text;
  try {
    return JSON.parse(text, reviver);
  } catch (e: any) {
    throw new Error(`Invalid JSON: ${e.message}`);
  }
  return text;
};
