import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { allowUsesCookie } from '../common/CookieConsent';
import PublicAPI from '@/api/TreedisPublicDAO';
import { jsonSafeParse } from '@/utils/json';

import DeviceManager from '@/components/util/deviceManager';

function shortenString(str: string) {
  if (!str) return str;
  if (str.length > 100) {
    return `${str.substring(0, 97)}...`;
  }
  return str;
}

async function getFingerprint() {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();
  return result.visitorId;
}

export const sendToGA = async (type: string, obj: any = null, isMultiPaths = false): Promise<void> => {
  const w = window as any;
  const scripts = document.getElementsByTagName('script');
  let gaId = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < scripts.length; i++) {
    const match = scripts[i].innerHTML.match(/'G-([\w-]+)'/);
    if (match) {
      gaId = match[0].replace(/'/g, '');
      break;
    }
  }

  if (gaId.startsWith('G-') && w.gtag) {
    w.gtag('event', obj.eventAction, {
      event_category: obj.eventCategory,
      event_label: shortenString(obj.eventLabel),
      ...(obj.eventId && gaId === 'G-R2FVP1NTYY' && { event_id: obj.eventId }),
      ...(obj.eventType && { event_type: obj.eventType }),
    });
  }

  if (gaId.startsWith('UA-') && w.ga) {
    w.ga('userViewId.send', type, obj);
  }

  const analyticUser = jsonSafeParse(window.localStorage.getItem('analyticUser'));
  const multiPaths = jsonSafeParse(window.localStorage.getItem('multiPath'));
  if (!analyticUser) {
    return;
  }
  const fingerprint = await getFingerprint();
  const documentLocation = document.location;
  const documentTitle = document.title;
  let device: string;
  if (DeviceManager.isMobile()) {
    device = 'mobile';
  } else {
    device = DeviceManager.isTablet() ? 'tablet' : 'desktop';
  }
  await PublicAPI.createAnalyticEvent({
    type,
    analyticUser,
    multiPaths: isMultiPaths ? multiPaths : [],
    category: obj.eventCategory,
    label: obj.eventLabel,
    action: obj.eventAction,
    eventId: obj.eventId,
    eventType: obj.eventType,
    quantity: obj.quantity,
    time: obj.time,
    device,
    browser: obj.browser,
    fingerprint,
    documentLocation,
    documentTitle,
  });
};

export const createGA = ({ id, customName }: { id: string; customName?: string }): void => {
  const w = window as any;
  if (id.startsWith('G-')) {
    w.gtag('js', new Date());
    w.gtag('config', id);
  }
  if (id.startsWith('UA-') && customName) {
    w.ga('create', id, { name: customName, cookieFlags: 'secure;samesite=none' });
    w.ga('send', 'pageview');
  }
};

export const startGA = async (tour: any = null, loaded: boolean, cookie: boolean = null): Promise<void> => {
  if (cookie === null) cookie = allowUsesCookie(tour?.hideCookie);
  if (!tour || !loaded || !cookie) {
    return;
  }

  const googleViewId = tour.client?.googleViewId || tour.user?.googleViewId;
  if (googleViewId) createGA({ id: googleViewId, customName: 'userViewId' });
  createGA({ id: 'G-R2FVP1NTYY' }); // default treedis ga4
  const fingerprint = await getFingerprint();
  const { language } = window.navigator;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  let device: string;
  if (DeviceManager.isMobile()) {
    device = 'mobile';
  } else {
    device = DeviceManager.isTablet() ? 'tablet' : 'desktop';
  }
  window.localStorage.removeItem('analyticUser');
  window.localStorage.removeItem('multiPath');
  const analyticUser = await PublicAPI.createAnalyticUser({ fingerprint, language, screenResolution, device });
  window.localStorage.setItem(
    'analyticUser',
    JSON.stringify({
      tour: { tourId: tour.id, tourMatterId: tour.matterId, tourName: tour.title },
    }),
  );
  sendToGA('event', {
    eventCategory: 'view',
    eventAction: 'view',
    eventLabel: 'view',
    browser: analyticUser?.result?.browser,
  });
};
