import _cloneDeep from 'lodash/cloneDeep';
import { TParentOptions } from './TreedisPublicDAO';

type APIOptions = {
  baseUrl?: string;
};

export default class API {
  public: any;

  baseUrl: string;

  constructor(options?: APIOptions) {
    this.baseUrl = options?.baseUrl || '';

    this.public = {
      get: async ({ url = '', ...rest }) => {
        try {
          if (rest.query) {
            url += `?${this.objectToQueryString(rest.query)}`;
          }

          const results = await fetch(this.baseUrl.concat(url), {
            method: 'get',
            ...rest,
          });
          return results.json();
        } catch (err) {
          if (err.code === 422) {
            const errors = Object.values(err?.errors || []);
            errors.map((error: string[]) => console.error(error.join(', ')));
          } else {
            console.error(err.message);
          }
        }
        return null;
      },

      post: async ({ url = '', ...rest }: any, parentOptions?: TParentOptions) => {
        try {
          /** NOTE: FormData can be passed only throw rest.data parameter */
          const data = rest.data ? rest.data : rest;
          const isFormData = rest.data instanceof FormData;

          let body;
          const config = rest.config ? rest.config : {};

          if (isFormData) {
            const headers = _cloneDeep(config.headers || {});
            headers['Content-Type'] = 'multipart/form-data';
            config.headers = headers;
            body = rest.data;
          } else {
            body = JSON.stringify(data);
          }

          const results = await fetch(this.baseUrl.concat(url), {
            method: 'post',
            ...rest,
            body,
            ...config,
          });
          return results.json();
        } catch (err) {
          if (!parentOptions?.turnOffToastError) {
            if (err.code === 422) {
              const errors = Object.values(err?.errors || []);
              errors.map((error: string[]) => console.error(error.join(', ')));
            } else {
              console.error(err.message);
            }
          } else return err;
        }
        return null;
      },

      put: async ({ url = '', ...rest }: any) => {
        try {
          const results = await fetch(this.baseUrl.concat(url), {
            method: 'put',
            ...rest,
            ...(rest.data
              ? {
                  body: JSON.stringify(rest.data),
                }
              : {
                  body: JSON.stringify(rest),
                }),
            ...(rest.config
              ? {
                  ...rest.config,
                }
              : {}),
          });
          return results.json();
        } catch (err) {
          if (err.code === 422) {
            const errors = Object.values(err?.errors || []);
            errors.map((error: string[]) => console.error(error.join(', ')));
          } else {
            console.error(err.message);
          }
        }
        return null;
      },

      patch: async ({ url = '', ...rest }: any) => {
        try {
          const results = await fetch(this.baseUrl.concat(url), {
            method: 'PATCH',
            ...rest,
            ...(rest.data
              ? {
                  body: JSON.stringify(rest.data),
                }
              : {
                  body: JSON.stringify(rest),
                }),
            ...(rest.config
              ? {
                  ...rest.config,
                }
              : {}),
          });
          return results.json();
        } catch (err) {
          return err;
        }
      },

      delete: async ({ url = '', ...rest }) => {
        try {
          const results = await fetch(this.baseUrl.concat(url), {
            method: 'delete',
            ...rest,
          });
          return results.json();
        } catch (err) {
          if (err.code === 422) {
            const errors = Object.values(err?.errors || []);
            errors.map((error: string[]) => console.error(error.join(', ')));
          } else {
            console.error(err.message);
          }
        }
        return null;
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  private objectToQueryString(obj: any) {
    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join('&');
  }
}
