import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';

import '@/styles/style.global.scss';
import '../_helpers/auth-header';

import { CacheProvider } from '@emotion/react';
import Head from 'next/head';
import { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import ThemeConfig from 'theme';

import createEmotionCache from 'theme/createEmotionCache';
import { LoadingProgressProvider } from '@/hooks/useLoadingProgress';
import DeviceManager from '../components/util/deviceManager';
import { CookieAcceptanceProvider } from '@/hooks/useCookies';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const [theme, setTheme] = useState<any>({});

  const updateTheme = useCallback(() => {
    setTheme({
      device: {
        mobile: DeviceManager.isMobile(),
        landscape: DeviceManager.isHorizontalMobileView(),
        ios: DeviceManager.isIOS(),
        tablet: DeviceManager.isTablet(),
      },
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateTheme);

    updateTheme();

    return () => {
      window.removeEventListener('resize', updateTheme);
    };
  }, [updateTheme]);

  return (
    <div id="page-root">
      <CacheProvider value={emotionCache}>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Head children={null} />
        {/* MUI Theme */}
        <ThemeConfig>
          {/* styled-components theme */}
          <ThemeProvider theme={theme}>
            <RecoilRoot>
              <LoadingProgressProvider>
                <CookieAcceptanceProvider>
                  <Component {...pageProps} />
                </CookieAcceptanceProvider>
              </LoadingProgressProvider>
            </RecoilRoot>
          </ThemeProvider>
        </ThemeConfig>
        <ToastContainer position="bottom-center" autoClose={5000} newestOnTop closeOnClick rtl={false} pauseOnHover />
      </CacheProvider>
    </div>
  );
}
