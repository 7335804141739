import API from './API';

export type TParentOptions = {
  turnOffToastError?: boolean;
};

class TreedisPublicDAO extends API {
  constructor() {
    super();
    const parent = this.public;

    this.public = {
      getUser: ({ ...options } = {}) => parent.get({ url: 'public/getUserIdByDomain', ...options }),

      getMinimaps: ({ ...options } = {}) => parent.post({ url: 'public/getMinimaps', ...options }),

      sendEmail: ({ ...options } = {}) => parent.post({ url: 'v1/public/sendEmail', ...options }),

      getGroups: ({ ...options } = {}) => parent.post({ url: 'v1/public/getSweepGroups', ...options }),

      getDuplicates: (id: number, { ...options } = {}) => parent.get({ url: `v1/public/getDuplicates/${id}`, ...options }),

      getReels: (id: number, { ...options } = {}) => parent.get({ url: `v1/public/getReels/${id}`, ...options }),

      getTranslations: (id: number, { ...options } = {}) => parent.get({ url: `v1/public/getTranslations/${id}`, ...options }),

      getAgents: (id: number, { ...options } = {}) => parent.get({ url: `v1/public/getAgents/${id}`, ...options }),

      getEmails: (id: number, { ...options } = {}) => parent.get({ url: `v1/public/getEmails/${id}`, ...options }),

      getTags: ({ ...options } = {}) => parent.post({ url: 'v1/public/getTags', ...options }),

      saveThread: ({ ...options } = {}) => parent.post({ url: 'v1/public/saveThread', ...options }),

      deleteThread: ({ ...options } = {}) => parent.post({ url: 'v1/public/deleteThread', ...options }),

      saveThreadMessage: ({ ...options } = {}) => parent.post({ url: 'v1/public/saveThreadMessage', ...options }),

      deleteThreadMessage: ({ ...options } = {}) => parent.post({ url: 'v1/public/deleteThreadMessage', ...options }),

      getProducts: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/products', ...options }),

      getProduct: ({ ...options } = {}) => parent.get({ url: `v1/public/eCommerce/products/get`, ...options }),

      getVariantsByTour: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/variants/byTour', ...options }),

      getMediaByTour: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/medias/byTour', ...options }),

      getLocationsByTour: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/locations', ...options }),

      createHavellsCart: ({ ...options } = {}) => parent.post({ url: 'v1/public/eCommerce/havells/cart/create', ...options }),

      addToHavellsCart: ({ ...options } = {}, parentOptions: TParentOptions = {}) => parent.post({ url: 'v1/public/eCommerce/havells/cart/add', ...options }, parentOptions),

      updateHavellsCart: ({ ...options } = {}) => parent.post({ url: 'v1/public/eCommerce/havells/cart/update', ...options }),

      getTwilioToken: ({ ...options } = {}) => parent.post({ url: `v1/public/video/token`, ...options }),

      getGame: ({ ...options } = {}) => parent.post({ url: 'v1/public/getGame/', ...options }),

      getTour: ({ ...options } = {}) => parent.post({ url: 'v1/public/getTour', ...options }),

      getTourBySlug: ({ ...options } = {}) => parent.post({ url: 'v1/public/getTourBySlug', ...options }),

      getAgentsAndTheirSlotsFromClientId: ({ ...options } = {}) => parent.get({ url: 'v1/public/getAgentsAndTheirSlotsFromClientId', ...options }),

      getTicketingDiscount: ({ ...options } = {}) => parent.get({ url: 'v1/public/getTicketingDiscount', ...options }),

      finishOrderTickets: ({ ...options } = {}) => parent.post({ url: 'v1/public/finishOrderTickets', ...options }),

      payTickets: (paymentType, { ...options } = {}) => parent.post({ url: `v1/public/ticket/payment/${paymentType}`, ...options }),

      createTicketOrder: ({ ...options } = {}) => parent.post({ url: 'v1/public/ticket/createOrder', ...options }),

      ticketLogin: ({ ...options } = {}, parentOptions: TParentOptions = {}) => parent.post({ url: 'v1/public/ticketLogin', ...options }, parentOptions),

      logoutTicket: ({ ...options } = {}, parentOptions: TParentOptions = {}) => parent.post({ url: 'v1/public/ticketLogout', ...options }, parentOptions),

      ticketSettings: (tourId: number, { ...options } = {}) =>
        parent.get({
          url: 'v1/public/ticket/settings',
          ...{
            ...options,
            query: { ...options, tourId },
          },
        }),

      getFloorPlans: ({ ...options } = {}) => parent.post({ url: 'v1/public/getFloorPlans', ...options }),

      getSweeps: ({ ...options } = {}) => parent.post({ url: 'v1/public/getSweeps', ...options }),

      getSweepIndex: ({ ...options } = {}) => parent.post({ url: 'v1/public/getSweepIndex', ...options }),

      getVimeoVideo: (videoId: string, clientId: number) =>
        parent.get({
          url: 'v1/public/vimeo',
          query: {
            clientId,
            path: `/videos/${videoId}?fields=uri,play,width,height,live,description,title`,
          },
        }),

      // @ts-ignore
      getVimeoVideoUrl: (url: string) => fetch(url, { method: 'HEAD', ignoreInterceptor: true }).then((res) => res.url),

      getLockByType: (options = {}) => parent.post({ url: `v1/public/getLockByType`, ...options }),
      getExperienceTopScores: (id: number, { ...options } = {}) => parent.get({ url: `v1/public/experience/${id}/topScores`, ...options }),
      getProductsWithPagination: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/products/productsFilters', ...options }),
      getProductsByTag: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/products/getByTagSid', ...options }),
      getProductsByPolygon: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/products/getByPolygonId', ...options }),
      getNearestProducts: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/products/getNearestProductsByCoordinates', ...options }),
      getProductsCount: ({ ...options } = {}) => parent.get({ url: 'v1/public/eCommerce/products/getProductsCount', ...options }),

      getTwinMakerConfig: (slug: string, { ...options } = {}) => parent.post({ url: `v1/public/tour/${slug}/twinmaker/config`, ...options }),
      getTwinMakerData: (slug: string, { ...options } = {}) => parent.post({ url: `v1/public/tour/${slug}/twinmaker/data`, ...options }),
      login: ({ ...options } = {}) => parent.post({ url: `v1/public/loginFromPublic`, ...options }),
      refreshOAuth: ({ ...options } = {}) => parent.post({ url: `v1/public/oauth/refresh`, ...options }),
      createAnalyticEvent: ({ ...options } = {}) => parent.post({ url: `v1/public/analytic/event`, ...options }),
      createAnalyticUser: ({ ...options } = {}) => parent.post({ url: `v1/public/analytic/user`, ...options }),

      // IOT
      getIoTDevices: ({ ...options } = {}) => parent.get({ url: 'v1/public/iot/devices', ...options }),
      getIoTProviders: ({ ...options } = {}) => parent.get({ url: 'v1/public/iot/providers', ...options }),
      getIoTMenu: ({ ...options } = {}) => parent.get({ url: 'v1/public/iot/menu', ...options }),
      unlockIoTDevice: ({ ...options } = {}) => parent.patch({ url: 'v1/public/iot/device/unlock', ...options }),
      getIoTGroups: ({ ...options } = {}) => parent.get({ url: 'v1/public/iot/groups', ...options }),
      getIoTDevicesTypes: ({ ...options } = {}) => parent.get({ url: 'v1/public/iot/devices/types', ...options }),
    };
  }
}

const DAO = new TreedisPublicDAO().public;

export default DAO;
